import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import _ from 'lodash';
import { INTERVIEWER } from '../common/roles';
import { getAuthUserFromSessionStorage, removeAuthUserFromSessionStorage } from '../common/constant';

const LoginGuard = () => {
  const userDetails = getAuthUserFromSessionStorage();
  let isAuthenticated = true;
  let route = "dashboard";
  if (_.isUndefined(userDetails) || _.isNull(userDetails) || _.isEmpty(userDetails)) {
    removeAuthUserFromSessionStorage();
    isAuthenticated = false;
  } else {
    if (userDetails.account_type == INTERVIEWER) {
        route = 'interviewer-dashboard';
    }
  }
  return isAuthenticated ? <Navigate to={"/"+route} /> : <Outlet />;
};

export default LoginGuard;
