import _ from 'lodash';
import moment from 'moment';
import { Toast } from 'primereact/toast';
import React, { Fragment, useEffect, useRef, useState } from "react";
import { Button, Card, DropdownButton, Form, InputGroup, Modal, Nav, OverlayTrigger, Tab, Table, Tooltip } from 'react-bootstrap';
import { CiFilter } from "react-icons/ci";
import { FaFileAlt, FaSort, FaSortDown, FaSortUp } from "react-icons/fa";
import { MdEdit } from "react-icons/md";
import { FiSettings } from "react-icons/fi";
import Loader from "react-js-loader";
import { useNavigate } from "react-router-dom";
import { API_URL, ITEMS_PER_PAGE, USER_STATUS_APPROVE, USER_STATUS_ON_HOLD, USER_STATUS_PENDING, USER_STATUS_REJECT, commonDateFormat, getAuthUserFromSessionStorage, getUserStatus, updateStatusLabel } from "../../common/constant";
import HeaderScreen from "../HeaderScreen/HeaderScreen";
import "./SuperAdminSettingScreen.css";
import { EMPLOYER_SUB_ADMIN, EMPLOYER_SUPER_ADMIN, INTERVIEWER, SUB_ADMIN } from '../../common/roles';
import CustomPaginationComponent from '../../common/CustomPaginationComponent/CustomPaginationComponent';
import NoRecordFound from '../../common/NoRecordFound/NoRecordFound'

const SuperAdminSettingScreen = () => {
    const navigate = useNavigate();
    const [userDetails, setUserType] = useState(getAuthUserFromSessionStorage());
    const [isLoading, setIsLoading] = useState(false);
    const [formErrors, setFormErrors] = useState("");
    const [formSuccess, setFormSuccess] = useState("");
    const [showFormErrors, setShowFormErrors] = useState(true);
    const [showFormSuccess, setShowFormSuccess] = useState(true);
    const [users, setUsers] = useState([]);
    const [totalCount, setTotalCount] = useState(0);
    const [searchText, setSearchText] = useState('');
    const debounceTimeout = useRef(null);
    const [pageNumber, setPageNumber] = useState(1);
    const [pageSize, setPageSize] = useState(ITEMS_PER_PAGE);
    const [sortConfig, setSortConfig] = useState({ key: 'createdAt', direction: 'desc' });
    const paingationRef = useRef();
    const [selectedAccountType, setSelectedAccountType] = useState(INTERVIEWER);

    const [showOpenResume, setShowOpenResume] = useState(false);
    const [interviewerDetails, setInterviewerDetails] = useState({});

    const [selectedFields, setSelectedFields] = useState({
        primary_skill: [],
        secondary_skill: [],
        designations: [],
        months: [],
        years: []
    });

    const months = [
        { id: 1, name: 'January' },
        { id: 2, name: 'February' },
        { id: 3, name: 'March' },
        { id: 4, name: 'April' },
        { id: 5, name: 'May' },
        { id: 6, name: 'June' },
        { id: 7, name: 'July' },
        { id: 8, name: 'August' },
        { id: 9, name: 'September' },
        { id: 10, name: 'October' },
        { id: 11, name: 'November' },
        { id: 12, name: 'December' }
    ];

    const [yearsList, setYearsList] = useState([]);

    const [skillsOptions, setSkillsOptions] = useState([]);
    const [designationOptions, setDesignationOptions] = useState([]);
    const getSkills = () => {
        fetch(`${API_URL}api/getSkills`)
            .then((response) => response.json())
            .then((response) => {
                setSkillsOptions(response.data)
            })
            .catch((error) => {
                setSkillsOptions([])
            });
    }

    const getPositions = () => {
        fetch(`${API_URL}api/getPositions`)
            .then((response) => response.json())
            .then((response) => {
                setDesignationOptions(response.data);
            })
            .catch((error) => {
                setDesignationOptions([])
            });
    }

    const handleCheckboxChange = (fieldType, value) => {
        setSelectedFields((prevSelectedFields) => {
            const isSelected = prevSelectedFields[fieldType].includes(value);
            let updatedFields = {
                ...prevSelectedFields,
                [fieldType]: isSelected
                    ? prevSelectedFields[fieldType].filter((item) => item !== value)
                    : [...prevSelectedFields[fieldType], value],
            };
            return updatedFields;
        });
    };

    const toast = useRef(null);

    useEffect(() => {
        if (!_.isUndefined(skillsOptions) && !_.isNull(skillsOptions) && (skillsOptions.length == 0)) {
            getSkills()
        }
        if (!_.isUndefined(designationOptions) && !_.isNull(designationOptions) && (designationOptions.length == 0)) {
            getPositions()
        }
        // Get the current year
        const currentYear = new Date().getFullYear();
        const startYear = 2024;
        const years = Array.from({ length: currentYear - startYear + 1 }, (_, index) => currentYear - index);
        setYearsList(years);
    }, []);

    useEffect(() => {
        getUsers();
    }, [pageNumber, pageSize, searchText, selectedFields, selectedAccountType, sortConfig])

    const getUsers = () => {
        setIsLoading(true)
        const apiUrl = `${API_URL}api/getUsers?page=${pageNumber}&limit=${pageSize}&sortBy=${sortConfig.key}&sortOrder=${sortConfig.direction}`;
        const requestBody = {
            ...selectedFields,
            account_type: selectedAccountType,
            search: searchText,
            company_id: ""
        }
        fetch(apiUrl, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${userDetails.token}`,
            },
            body: JSON.stringify(requestBody)
        })
            .then((response) => response.json())
            .then((response) => {
                setIsLoading(false)
                if (!_.isUndefined(response) && !_.isNull(response) && !_.isUndefined(response.data) && !_.isNull(response.data)) {
                    setUsers(response.data);
                    setTotalCount(response.totalCount)
                }
            })
            .catch((err) => {
                setIsLoading(false)
                setUsers([]);
            });
    }

    const resetPagination = () => {
        setPageNumber(1)
        if (paingationRef.current) {
            paingationRef.current.setCurrentPage(1)
        }
    }

    const updatePagination = (pageNumber, pageSize) => {
        setPageNumber(pageNumber)
        setPageSize(pageSize)
    }

    const handleCloseFormErrors = () => {
        setShowFormErrors(false);
        setFormErrors("");
    };

    const handleCloseFormSuccess = () => {
        setShowFormSuccess(false);
        setFormSuccess("");
    };

    const redirectEditProfilePage = (user) => {
        const id = user.id;
        if (user.account_type === INTERVIEWER) {
            navigate("/interviewer-profile-page/" + id, { replace: true });
        } else if (user.account_type === EMPLOYER_SUPER_ADMIN || user.account_type === EMPLOYER_SUB_ADMIN) {
            navigate("/company-profile-page/" + id, { replace: true });
        } else if (user.account_type === SUB_ADMIN) {
            navigate("/admin-profile-page/" + id, { replace: true });
        }
    }

    const updateUserStatus = (id, status, userType = "Interviewer") => {
        setIsLoading(true)
        const params = {
            id: id,
            status: status
        };
        fetch(`${API_URL}api/updateStatus`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${userDetails.token}`,
            },
            body: JSON.stringify(params)
        })
            .then((response) => response.json())
            .then((response) => {
                toast.current.show({ severity: 'success', summary: 'Success', detail: `${userType} ${updateStatusLabel(status)} Successfully`, life: 3000 });
                getUsers();
                setIsLoading(false)
            })
            .catch((err) => {
                setIsLoading(false)
                toast.current.show({ severity: 'error', summary: 'Error', detail: "Something went wrong", life: 3000 });
            });
    }

    const onSort = (key) => {
        let direction = 'asc';
        if (sortConfig.key === key && sortConfig.direction === 'asc') {
            direction = 'desc';
        }
        setSortConfig({ key, direction });
    };

    const getSortIcon = (key) => {
        if (sortConfig.key !== key) {
            return <FaSort className="cursor-pointer" />;
        }
        if (sortConfig.direction === 'desc') {
            return <FaSortUp className="cursor-pointer" />;
        }
        return <FaSortDown className="cursor-pointer" />;
    };

    const clearFilter = () => {
        setSelectedFields({
            primary_skill: [],
            secondary_skill: [],
            designations: [],
            months: [],
            years: []
        })
    }

    const setAccountType = (account_type) => {
        resetPagination()
        setSelectedAccountType(account_type)
    }

    return (
        <Fragment>
            <Toast ref={toast} />
            {
                isLoading &&
                <div className="spinner-loader">
                    <Loader type="spinner-circle" bgColor="#2F2F74" color="#2F2F74" size={100} />
                </div>
            }
            <div className="container-fluid employer-dashboard-container fixed-padding settings_mob">
                <div className="row">
                    <div className="col-12 px-0">
                        <HeaderScreen />
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 employer-dashboard-title ">
                        <div className="row mb-3 mb-md-0 justify-content-md-between align-items-center pageTitle_fixed">
                            <div className="col-12 col-md-6 col-lg-3">
                                <p className="employer-dashboard-jobs-posted mb-0 p-0" style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <span className='inner_title d-flex align-items-center gap-2'><FiSettings size={18} />SETTINGS</span>

                                </p>
                            </div>
                            <div className='col-12 col-md-6 col-lg-3'>
                                <input type="text" className='form-control' placeholder="Search" style={{ paddingLeft: '12px' }} onChange={(e) => {
                                    const value = e.target.value.trim();
                                    if (debounceTimeout.current) {
                                        clearTimeout(debounceTimeout.current);
                                    }
                                    debounceTimeout.current = setTimeout(() => {
                                        setSearchText(value);
                                    }, 500);
                                }} />
                            </div>
                        </div>
                        <Tab.Container defaultActiveKey="interviewer" onSelect={() => {
                            setSearchText("")
                        }}>
                            <div className="row mb-2">
                                <div className="col-12">
                                    <Nav fill variant="tabs">
                                        <Nav.Item>
                                            <Nav.Link eventKey="interviewer" onClick={() => {
                                                clearFilter();
                                                setAccountType(INTERVIEWER)
                                            }}>Interviewer</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="company" onClick={() => {
                                                clearFilter();
                                                setAccountType(EMPLOYER_SUPER_ADMIN);
                                            }}>Company</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="ralaAdmins" onClick={() => {
                                                clearFilter();
                                                setAccountType(SUB_ADMIN)
                                            }}>Rala admins</Nav.Link>
                                        </Nav.Item>
                                    </Nav>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <Tab.Content>
                                        <Card>
                                            <Card.Body>
                                                <div className="row">
                                                    {
                                                        selectedAccountType === INTERVIEWER && (
                                                            <div className="col-1 report-filter-section" style={{ width: '50px' }}>
                                                                <OverlayTrigger
                                                                    placement="top"
                                                                    overlay={<Tooltip>Filters</Tooltip>}
                                                                >
                                                                    <DropdownButton id="report-filter" title={<CiFilter style={{ width: '25px', height: '25px' }} />} style={{ backgroundImage: 'none' }} >

                                                                        <DropdownButton className="nested-button" title={
                                                                            "Primary Skills" + (selectedFields.primary_skill.length > 0 ? ` (${selectedFields.primary_skill.length})` : "")
                                                                        }>
                                                                            {skillsOptions &&
                                                                                skillsOptions.map((item, index) => (
                                                                                    <InputGroup className="flex px-2" style={{ justifyContent: 'space-between' }} key={item._id}>
                                                                                        <span title={item.name} className="filter-item">{item.name}</span>
                                                                                        <InputGroup.Checkbox
                                                                                            key={"primary_skill_" + index}
                                                                                            aria-label="Checkbox for following text input"
                                                                                            onChange={() => handleCheckboxChange('primary_skill', item.name)}
                                                                                            checked={selectedFields.primary_skill.includes(item.name)}
                                                                                        />
                                                                                    </InputGroup>
                                                                                ))}
                                                                        </DropdownButton>

                                                                        <DropdownButton className="nested-button" title={
                                                                            "Secondary Skills" + (selectedFields.secondary_skill.length > 0 ? ` (${selectedFields.secondary_skill.length})` : "")
                                                                        }>
                                                                            {skillsOptions &&
                                                                                skillsOptions.map((item, index) => (
                                                                                    <InputGroup className="flex px-2" style={{ justifyContent: 'space-between' }} key={item._id}>
                                                                                        <span title={item.name} className="filter-item">{item.name}</span>
                                                                                        <InputGroup.Checkbox
                                                                                            key={"secondary_skill_" + index}
                                                                                            aria-label="Checkbox for following text input"
                                                                                            onChange={() => handleCheckboxChange('secondary_skill', item.name)}
                                                                                            checked={selectedFields.secondary_skill.includes(item.name)}
                                                                                        />
                                                                                    </InputGroup>
                                                                                ))}
                                                                        </DropdownButton>

                                                                        <DropdownButton className="nested-button" title={
                                                                            "Designation" + (selectedFields.designations.length > 0 ? ` (${selectedFields.designations.length})` : "")
                                                                        }>
                                                                            {designationOptions &&
                                                                                designationOptions.map((item, index) => (
                                                                                    <InputGroup className="flex px-2" style={{ justifyContent: 'space-between' }} key={item._id}>
                                                                                        <span title={item.name} className="filter-item">{item.name}</span>
                                                                                        <InputGroup.Checkbox
                                                                                            key={"designation_" + index}
                                                                                            aria-label="Checkbox for following text input"
                                                                                            onChange={() => handleCheckboxChange('designations', item.name)}
                                                                                            checked={selectedFields.designations.includes(item.name)}
                                                                                        />
                                                                                    </InputGroup>
                                                                                ))}
                                                                        </DropdownButton>

                                                                        <DropdownButton className="nested-button" title={
                                                                            "Years" + (selectedFields.years.length > 0 ? ` (${selectedFields.years.length})` : "")
                                                                        }>
                                                                            {yearsList && yearsList.map((item, index) => (
                                                                                <InputGroup className="flex px-2" style={{ justifyContent: 'space-between' }} key={item}>
                                                                                    <span title={item} className="filter-item">{item}</span>
                                                                                    <InputGroup.Checkbox
                                                                                        key={"year_" + index}
                                                                                        aria-label="Checkbox for following text input"
                                                                                        onChange={() => handleCheckboxChange('years', item)}
                                                                                        checked={selectedFields.years.includes(item)}
                                                                                    />
                                                                                </InputGroup>
                                                                            ))}
                                                                        </DropdownButton>

                                                                        <DropdownButton className="nested-button" title={
                                                                            "Months" + (selectedFields.months.length > 0 ? ` (${selectedFields.months.length})` : "")
                                                                        }>
                                                                            {months && months.map((item, index) => (
                                                                                <InputGroup className="flex px-2" style={{ justifyContent: 'space-between' }} key={item.id}>
                                                                                    <span title={item.name} className="filter-item">{item.name}</span>
                                                                                    <InputGroup.Checkbox
                                                                                        key={"month_" + index}
                                                                                        aria-label="Checkbox for following text input"
                                                                                        onChange={() => handleCheckboxChange('months', item.id)}
                                                                                        checked={selectedFields.months.includes(item.id)}
                                                                                    />
                                                                                </InputGroup>
                                                                            ))}
                                                                        </DropdownButton>

                                                                        <div className="my-2" style={{ textAlign: 'center' }}>
                                                                            <Button onClick={clearFilter} style={{
                                                                                marginTop: '0px',
                                                                                background: '#FFFBFE',
                                                                                color: 'black',
                                                                                border: 'none'
                                                                            }} className="employer-dashboard-interview-join">
                                                                                Reset
                                                                            </Button>
                                                                        </div>
                                                                    </DropdownButton>
                                                                </OverlayTrigger>
                                                            </div>
                                                        )
                                                    }
                                                    <div className={selectedAccountType === INTERVIEWER ? 'col-11' : 'col-12'} style={{ flex: 1 }}>
                                                        <Table bordered striped hover size="lg" responsive className="request-interviewer-users-feednback-completed">
                                                            <thead className='sticky-top'>
                                                                <tr>
                                                                    <th className='text-start' onClick={() => onSort('id')}>Registration ID {getSortIcon('id')}</th>
                                                                    <th className='text-start' onClick={() => onSort('first_name')}>Name {getSortIcon('name')}</th>
                                                                    {
                                                                        selectedAccountType === INTERVIEWER && (
                                                                            <th className='text-start' onClick={() => onSort('resume')}>Resume {getSortIcon('resume')}</th>
                                                                        )
                                                                    }
                                                                    <th className='text-start' onClick={() => onSort('email')}>Email ID {getSortIcon('email')}</th>
                                                                    <th onClick={() => onSort('mobile_number')}>Mobile Number {getSortIcon('mobile_number')}</th>
                                                                    {
                                                                        selectedAccountType === INTERVIEWER && (
                                                                            <>
                                                                                <th className='text-start' style={{ minWidth: '450px' }} onClick={() => onSort('primary_skill')}>Primary skills {getSortIcon('primary_skill')}</th>
                                                                                <th className='text-start' style={{ minWidth: '450px' }} onClick={() => onSort('secondary_skill')}>Seconday skills {getSortIcon('secondary_skill')}</th>
                                                                                <th className='text-start' onClick={() => onSort('designation')}>Designation {getSortIcon('designation')}</th>
                                                                            </>
                                                                        )
                                                                    }
                                                                    <th className='text-start' onClick={() => onSort('createdAt')}>Registered Date {getSortIcon('createdAt')}</th>
                                                                    <th className='text-start' onClick={() => onSort('user_status')}>Profile Status {getSortIcon('user_status')}</th>
                                                                    <th className='text-start'>Action</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {
                                                                    !_.isUndefined(users) && !_.isNull(users) &&
                                                                    _.map(users, (user, index) => {
                                                                        return <tr key={"feednback-completed-" + index}>
                                                                            <td>{user._id}</td>
                                                                            <td className='fw-bold'><span>
                                                                                {
                                                                                    selectedAccountType === EMPLOYER_SUPER_ADMIN ? user.company_name : `${user.first_name} ${user.last_name}`
                                                                                }
                                                                            </span></td>
                                                                            {
                                                                                selectedAccountType === INTERVIEWER && (
                                                                                    <td style={{ textAlign: 'center' }}>
                                                                                        <OverlayTrigger
                                                                                            placement="top"
                                                                                            overlay={<Tooltip>Interviewer Resume</Tooltip>}
                                                                                        >
                                                                                            <a style={{ color: 'black' }} className="cursor-pointer" onClick={() => { setInterviewerDetails(user); setShowOpenResume(true); }}><FaFileAlt /></a>
                                                                                        </OverlayTrigger>
                                                                                    </td>
                                                                                )
                                                                            }
                                                                            <td>{user.email}</td>
                                                                            <td>{user.mobile_number}</td>
                                                                            {
                                                                                selectedAccountType === INTERVIEWER && (
                                                                                    <>
                                                                                        <td>{user.primary_skill.join(", ")}</td>
                                                                                        <td>{user.secondary_skill.join(", ")}</td>
                                                                                        <td>{user.designation}</td>
                                                                                    </>
                                                                                )
                                                                            }

                                                                            <td>{commonDateFormat(user.createdAt)}</td>
                                                                            <td>{getUserStatus(user.user_status)}</td>
                                                                            <td style={{ minWidth: '250px' }}>
                                                                                <div className="d-flex">
                                                                                    <OverlayTrigger
                                                                                        placement="top"
                                                                                        overlay={<Tooltip>Edit Profile</Tooltip>}
                                                                                    >
                                                                                        <Button onClick={() => redirectEditProfilePage(user)} variant="outline-primary">
                                                                                            <MdEdit />
                                                                                        </Button>
                                                                                    </OverlayTrigger>
                                                                                    &nbsp;&nbsp;
                                                                                    <Form.Select style={{ width: '120px' }}
                                                                                        onChange={(e) => {
                                                                                            updateUserStatus(
                                                                                                user._id,
                                                                                                e.target.value,
                                                                                                "Interviewer"
                                                                                            )
                                                                                        }}
                                                                                    >
                                                                                        <option value="PENDING" selected={user.user_status === USER_STATUS_PENDING}>Pending</option>
                                                                                        <option value="APPROVE" selected={user.user_status === USER_STATUS_APPROVE}>Approve</option>
                                                                                        <option value="REJECT" selected={user.user_status === USER_STATUS_REJECT}>Reject</option>
                                                                                        <option value="ON_HOLD" selected={user.user_status === USER_STATUS_ON_HOLD}>On Hold</option>
                                                                                    </Form.Select>
                                                                                </div>
                                                                            </td>

                                                                        </tr>
                                                                    })
                                                                }
                                                            </tbody>
                                                        </Table>
                                                        {
                                                            !_.isUndefined(users) && !_.isNull(users) && users.length === 0 && (
                                                                <NoRecordFound />
                                                            )
                                                        }
                                                    </div>
                                                </div>
                                                {
                                                    totalCount > 0 && (
                                                        <CustomPaginationComponent ref={paingationRef} total={totalCount} updatePagination={updatePagination} />
                                                    )
                                                }
                                            </Card.Body>
                                        </Card>
                                    </Tab.Content>
                                </div>
                            </div>
                        </Tab.Container>
                    </div>
                </div>
            </div>
            {
                formErrors && showFormErrors &&
                <Modal show={showFormErrors} onHide={handleCloseFormErrors} animation={false}>
                    <Modal.Header closeButton>
                        <Modal.Title>Error</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>{formErrors}</Modal.Body>
                    <Modal.Footer>
                        <Button variant="primary" onClick={handleCloseFormErrors}>
                            OK
                        </Button>
                    </Modal.Footer>
                </Modal>
            }
            {
                formSuccess && showFormSuccess &&
                <Modal show={showFormSuccess} onHide={handleCloseFormSuccess} animation={false}>
                    <Modal.Header closeButton>
                        <Modal.Title>Success</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>{formSuccess}</Modal.Body>
                    <Modal.Footer>
                        <Button variant="primary" onClick={handleCloseFormSuccess}>
                            OK
                        </Button>
                    </Modal.Footer>
                </Modal>
            }
            {
                showOpenResume && !_.isUndefined(interviewerDetails) && !_.isNull(interviewerDetails) && !_.isUndefined(interviewerDetails.id) && !_.isNull(interviewerDetails.id) && (interviewerDetails.id !== '') &&
                <Modal centered dialogClassName="modal-90w" show={showOpenResume} onHide={() => { setShowOpenResume(false) }} animation={false}>
                    <Modal.Header closeButton>

                    </Modal.Header>
                    <Modal.Body>
                        {
                            interviewerDetails.resume && interviewerDetails.resume.includes('.doc') ?
                                <iframe key="iframe-doc" style={{
                                    width: '100%',
                                    height: '80vh'
                                }} src={`https://docs.google.com/gview?url=${interviewerDetails.resume}&embedded=true`}></iframe> :
                                <object key="object-other" data={interviewerDetails.resume} style={{
                                    width: '100%',
                                    height: '80vh'
                                }}></object>
                        }
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => { setShowOpenResume(false) }}>
                            Ok
                        </Button>
                    </Modal.Footer>
                </Modal>
            }
        </Fragment>
    )
}
export default SuperAdminSettingScreen;