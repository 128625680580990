import _ from 'lodash';
import React, { useState } from "react";
import { Card, Form } from 'react-bootstrap';
import { AiFillSetting } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import default_profile_picture from "../../Assets/default-edit-profile-picture.png";
import { API_URL, addAuthUserToSessionStorage, getAuthUserFromSessionStorage } from "../../common/constant";
import HeaderScreen from "../HeaderScreen/HeaderScreen";
import "./ProfileSettingsScreen.css";

const ProfileSettingsScreen = () => {
    const navigate = useNavigate();
    const [userDetails, setUserType] = useState(getAuthUserFromSessionStorage());
    const [formErrors, setFormErrors] = useState({});
    const [isSubmit, setIsSubmit] = useState(false);
    const [showFormErrors, setShowFormErrors] = useState(true);
    const [locationPreferencesError, setLocationPreferencesError] = useState("");
    const [workExperienceError, setWorkExperienceError] = useState("");
    const [educationError, setEducationError] = useState("");
    const [skillsError, setSkillsError] = useState("");
    const [bankingDetailsError, setBankingDetailsError] = useState("");
    const [certificatesError, setCertificatesError] = useState("");
    const [documentsError, setDocumentsError] = useState("");
    const [languagesError, setLanguagesError] = useState("");
    const [user, setUserDetails] = useState({
        locationPreferences: "",
        workExperience: "",
        education: "",
        skills: "",
        bankingDetails: "",
        certificates: "",
        documents: "",
        languages: ""
    });

    const changeHandler = (e) => {
        const { name, value } = e.target;
        setUserDetails({
            ...user,
            [name]: value,
        });
    };
    const validateForm = () => {
      const emailregex = /^[^\s+@]+@[^\s@]+\.[^\s@]{2,}$/i;
    };

    const checkedValidateForm = () => {
        return true;
      };

    const submitHandler = (e) => {
      e.preventDefault();
      validateForm();
      if (checkedValidateForm()) {
        setIsSubmit(true);
        let params = {
            location_preferences: user.locationPreferences,
            work_experience: user.workExperience,
            education: user.education,
            skills: user.skills,
            banking_details: user.bankingDetails,
            documents: user.documents,
            languages: user.languages
        }
        fetch(`${API_URL}api/updateProfile`, {
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${userDetails.token}`,
          },
          body: JSON.stringify(params)
        })
          .then((response) => response.json())
          .then((response) => {
            setIsSubmit(false);
            if (!_.isUndefined(response) && !_.isNull(response) && !_.isUndefined(response.status) && !_.isNull(response.status) && (response.status === 200) && !_.isUndefined(response.message) && !_.isNull(response.message) && (response.message !== ''))
            {
                addAuthUserToSessionStorage(response.user);
                navigate("/dashboard", { replace: true });
            }
            else if (!_.isUndefined(response) && !_.isNull(response) && !_.isUndefined(response.message) && !_.isNull(response.message) && (response.message !== ''))
            {
                setShowFormErrors(true);
                setFormErrors(response.message);
            }
          })
          .catch((error) => {
            if (!_.isUndefined(error) && !_.isNull(error) && !_.isUndefined(error.message) && !_.isNull(error.message) && (error.message !== '')) {
              setShowFormErrors(true);
              setFormErrors(error.message);
            }
          });
      }
    };

    const getProfileImage = () => {
        if (!_.isUndefined(userDetails) && !_.isNull(userDetails) && !_.isUndefined(userDetails.profile_image) && userDetails.profile_image != "") {
            return userDetails.profile_image;
        }
        return default_profile_picture;
    }

    return (
        <div className="container-fluid profile-settings-container">
            <div className="row">
                <div className="col-12 px-0">
                    <HeaderScreen/>
                </div>
            </div>
            <div className="row">
                <div className="col-12 profile-settings-title-col">
                    <AiFillSetting size={24}/><span className="profile-settings-title">Profile Settings</span>
                </div>
            </div>
            <div className="row mt-5 profile-settings-container-row">
                <div className="col-3">
                    <Card className="mx-5 my-auto">
                        <Card.Body>
                            <div className="row">
                                <div className="col-12 text-center">
                                    <div className="profile-settings-image-div">
                                        <img src={getProfileImage()} alt="Profile" className="profile-settings-image"/>
                                    </div>
                                </div>
                            </div>
                            <div className="row mt-4">
                                <div className="col-12">
                                    <p className="profile-settings-interviewer">Interviewer</p>
                                    <p className="profile-settings-interviewer-label">Email</p>
                                    <p className="profile-settings-interviewer-value">xyz@gmaIl.com</p>
                                    <p className="profile-settings-interviewer-label">Mobie Number</p>
                                    <p className="profile-settings-interviewer-value">+91 893*******</p>
                                    <p className="profile-settings-interviewer-label">Skill</p>
                                    <p className="profile-settings-interviewer-value">Front-end</p>
                                    <p className="profile-settings-interviewer-label">Sign Up Date</p>
                                    <p className="profile-settings-interviewer-value">11 NOV 2022, 02:33 PM</p>
                                </div>
                            </div>
                        </Card.Body>
                    </Card>
                </div>
                <div className="col-9">
                    <Card>
                        <Card.Body>
                            <Form>
                                <Form.Group className="mb-4">
                                    <Form.Control type="text" placeholder="Location Preferences" />
                                </Form.Group>
                                <Form.Group className="mb-4">
                                    <Form.Control type="text" placeholder="Work Experience" />
                                </Form.Group>
                                <Form.Group className="mb-4">
                                    <Form.Control type="text" placeholder="Education" />
                                </Form.Group>
                                <Form.Group className="mb-4">
                                    <Form.Control type="text" placeholder="Skills" />
                                </Form.Group>
                                <Form.Group className="mb-4">
                                    <Form.Control type="text" placeholder="Banking Details" />
                                </Form.Group>
                                <Form.Group className="mb-4">
                                    <Form.Control type="text" placeholder="Certificates" />
                                </Form.Group>
                                <Form.Group className="mb-4">
                                    <Form.Control type="text" placeholder="Documents" />
                                </Form.Group>
                                <Form.Group className="mb-4">
                                    <Form.Control type="text" placeholder="Languages" />
                                </Form.Group>
                            </Form>
                        </Card.Body>
                    </Card>
                </div>
            </div>
        </div>
    );
};
export default ProfileSettingsScreen;