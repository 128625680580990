import React, { forwardRef, useImperativeHandle, useState } from 'react';
import './Autocomplete.css'
import { Form, ListGroup } from 'react-bootstrap';

const Autocomplete = forwardRef(({ suggestions, onSelect }, ref) => {

    useImperativeHandle(ref, () => ({
        setInputValue
    }));

    const [inputValue, setInputValue] = useState('');
    const [filteredSuggestions, setFilteredSuggestions] = useState([]);
    const [activeSuggestionIndex, setActiveSuggestionIndex] = useState(-1);
    const [showSuggestions, setShowSuggestions] = useState(false);
    const [selectedValue, setSelectedValue] = useState(null); // Store the selected value

    const onChange = (e) => {
        const userInput = e.target.value;
        const filtered = suggestions.filter(
            suggestion => suggestion.searchableLabel.toLowerCase().indexOf(userInput.toLowerCase()) >= 0 || suggestion.label.toLowerCase().indexOf(userInput.toLowerCase()) >= 0
        );
        setInputValue(userInput);
        setFilteredSuggestions(filtered);
        setActiveSuggestionIndex(-1);
        setShowSuggestions(true);
    };

    const onClick = (suggestion) => {
        setInputValue(suggestion.label); // Show the label in the input
        setSelectedValue(suggestion.value); // Store the value (ID or key)
        setFilteredSuggestions([]);
        setActiveSuggestionIndex(-1);
        setShowSuggestions(false);
        onSelect(suggestion.value);
    };

    const onKeyDown = (e) => {
        if (e.keyCode === 13) { // Enter key
            e.preventDefault();
            if (activeSuggestionIndex > -1) {
                const suggestion = filteredSuggestions[activeSuggestionIndex];
                setInputValue(suggestion.label); // Show the label
                setSelectedValue(suggestion.value); // Store the value
                setShowSuggestions(false);
            }
        } else if (e.keyCode === 38) { // Arrow up
            if (activeSuggestionIndex === 0) return;
            setActiveSuggestionIndex(activeSuggestionIndex - 1);
        } else if (e.keyCode === 40) { // Arrow down
            if (activeSuggestionIndex === filteredSuggestions.length - 1) return;
            setActiveSuggestionIndex(activeSuggestionIndex + 1);
        }
    };

    const renderSuggestions = () => {
        if (showSuggestions && inputValue) {
            if (filteredSuggestions.length > 0) {
                return (
                    <ListGroup className="autocomplete-items">
                        {filteredSuggestions.map((suggestion, index) => {
                            let className = 'cursor-pointer';
                            if (index === activeSuggestionIndex) {
                                className = className + ' autocomplete-active';
                            }
                            return (
                                <ListGroup.Item key={suggestion.value} className={className} onClick={() => onClick(suggestion)}>{suggestion.label}</ListGroup.Item>
                            );
                        })}
                    </ListGroup>
                );
            } else {
                return (
                    <ListGroup className="autocomplete-items">
                        <ListGroup.Item >No suggestions</ListGroup.Item>
                    </ListGroup>
                );
            }
        }
        return null;
    };

    return (
        <div className="autocomplete">
            <Form.Control
                type="text"
                id="suggestion"
                name="suggestion"
                value={inputValue}
                placeholder="Start typing..."
                onKeyDown={onKeyDown}
                onChange={onChange}
                maxLength={25}
                autoComplete="off"
            />
            {renderSuggestions()}
        </div>
    );
});

export default Autocomplete;
