import _ from 'lodash';
import React from "react";
import no_data_found_img from "../../Assets/no-data-found.jpeg";

const NoRecordFound = () => {
    return (
        <div className='text-center'>
            <img alt='No records found' src={no_data_found_img} />
        </div>
    )
}
export default NoRecordFound;