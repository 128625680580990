import _ from 'lodash';
import moment from 'moment';
import React, { Fragment, forwardRef, useEffect, useRef, useState } from "react";
import { Button, Card, Form, ListGroup, Modal, OverlayTrigger, Table, Tooltip } from 'react-bootstrap';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { BiSolidVideos } from "react-icons/bi";
import { FaFileAlt, FaSort, FaSortDown, FaSortUp } from "react-icons/fa";
import { RxCountdownTimer } from "react-icons/rx";
import Loader from "react-js-loader";
import { NavLink } from "react-router-dom";
import { API_URL, INTERVIEW_FINAL_STATUS_REJECTED, INTERVIEW_FINAL_STATUS_SELECTED, ITEMS_PER_PAGE, PROXY, QC_STATUS_FAIL, QC_STATUS_PASS, SCHEDULER_STATUS_DONE, commonDateFormat, getAuthUserFromSessionStorage, getInterviewFinalStatus, getQCStatuslabel, isDateGreaterThanToday, pascalCase } from "../../common/constant";
import HeaderScreen from "../HeaderScreen/HeaderScreen";
import "./HistoryScreen.css";
import CustomPaginationComponent from '../../common/CustomPaginationComponent/CustomPaginationComponent';
import NoRecordFound from '../../common/NoRecordFound/NoRecordFound'

const HistoryScreen = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [interviewStatus, setInterviewStatus] = useState("");
    const currentFromDate = new Date();
    const currentUTCDate = new Date(Date.UTC(currentFromDate.getUTCFullYear(), currentFromDate.getUTCMonth(), currentFromDate.getUTCDate(), 0, 0, 0, 0));
    // Get the date for one month prior in UTC
    const oneMonthPriorDate = new Date(currentUTCDate);
    oneMonthPriorDate.setUTCMonth(currentUTCDate.getUTCMonth() - 1);
    const [fromDate, setFromDate] = useState(oneMonthPriorDate);
    const [toDate, setToDate] = useState(currentFromDate);
    const [userDetails, setUserType] = useState(getAuthUserFromSessionStorage());
    const [interviews, setInterviews] = useState([]);
    const [totalCount, setTotalCount] = useState(0);
    const [pageNumber, setPageNumber] = useState(1);
    const [pageSize, sepageSize] = useState(ITEMS_PER_PAGE);
    const [sortConfig, setSortConfig] = useState({ key: 'scheduled_on', direction: 'desc' });
    const paingationRef = useRef();

    const [isShowVideoModal, setIsShowVideoModal] = useState(false);
    const [recordings, setRecordings] = useState([]);

    useEffect(() => {
        if (!_.isUndefined(interviews) && !_.isNull(interviews) && (interviews.length === 0)) {
            getHistory()
        }
    }, []);

    useEffect(() => {
        getHistory();
    }, [pageNumber, pageSize, interviewStatus, sortConfig])

    const getHistory = () => {
        let endDate = toDate;
        if (endDate) {
            endDate = new Date(endDate);
            endDate.setHours(23, 59, 59, 999);
        }
        let requestBody = {
            startDate: fromDate,
            scheduler_status: SCHEDULER_STATUS_DONE,
            endDate,
            interviewStatus
        }
        setIsLoading(true)
        const apiUrl = `${API_URL}api/interview/get-history/${userDetails.id}?page=${pageNumber}&limit=${pageSize}&sortBy=${sortConfig.key}&sortOrder=${sortConfig.direction}`;
        fetch(apiUrl, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${userDetails.token}`,
            },
            body: JSON.stringify(requestBody)
        }
        )
            .then((response) => response.json())
            .then((response) => {
                if (!_.isUndefined(response) && !_.isNull(response) && !_.isUndefined(response.data) && !_.isNull(response.data)) {
                    setInterviews(response.data);
                    setTotalCount(response.totalCount);
                }
                setIsLoading(false)
            })
            .catch((err) => {
                setInterviews([]);
                setTotalCount(0);
                setIsLoading(false)
            });
    }

    const resetPagination = () => {
        setPageNumber(1)
        if (paingationRef.current) {
            paingationRef.current.setCurrentPage(1)
        }
    }

    const updatePagination = (pageNumber, pageSize) => {
        setPageNumber(pageNumber)
        sepageSize(pageSize)
    }

    const handleClear = (name) => {
        if (name === 'toDate') {
            setToDate(null);
        } else {
            setFromDate(null);
        }
    };

    const CustomInput = forwardRef(({ value, onClick, onClear }, ref) => (
        <div style={{ position: 'relative', display: 'inline-block' }}>
            <input
                value={value}
                onClick={onClick}
                ref={ref}
                readOnly
                style={{ width: '100%' }}
                placeholder="dd/mm/yyyy"
                className="form-control"
            />
            {value && (
                <button
                    type="button"
                    onClick={onClear}
                    style={{
                        position: 'absolute',
                        right: '5px',
                        top: '50%',
                        transform: 'translateY(-50%)',
                        cursor: 'pointer',
                        background: 'none',
                        border: 'none',
                        fontSize: '16px',
                        color: '#d9534f' // Bootstrap danger color
                    }}
                >
                    &#x2715; {/* Unicode character for an "X" mark */}
                </button>
            )}
        </div>
    ));

    const onSort = (key) => {
        let direction = 'asc';
        if (sortConfig.key === key && sortConfig.direction === 'asc') {
            direction = 'desc';
        }
        setSortConfig({ key, direction });
    };


    const getSortIcon = (key) => {
        if (sortConfig.key !== key) {
            return <FaSort className="cursor-pointer" />;
        }
        if (sortConfig.direction === 'desc') {
            return <FaSortUp className="cursor-pointer" />;
        }
        return <FaSortDown className="cursor-pointer" />;
    };

    const isNotEditable = (interviewData) => {
        return !isDateGreaterThanToday(interviewData.record_editable_till);
    }

    return (
        <Fragment>
            {
                isLoading &&
                <div className="spinner-loader">
                    <Loader type="spinner-circle" bgColor="#2F2F74" color="#2F2F74" size={100} />
                </div>
            }
            <div className="container-fluid history-container fixed-padding hist-padding">
                <div className="row">
                    <div className="col-12 px-0">
                        <HeaderScreen />
                    </div>
                </div>
                <div className="row pb-2 pageTitle_fixed">
                    <div className="col-12 col-md-12 col-lg-4 col-xl-6 my-auto history-title ">
                        <RxCountdownTimer size={20} className="header-icon ms-4" />HISTORY
                    </div>
                    <div className='col-12 col-md-12 col-lg-8 col-xl-6'>
                        <div className='row justify-content-end mt-3'>
                            <div className="col-12 col-lg-2 my-1 my-md-1 my-lg-auto">
                                <Form.Select aria-label="Choose" id="interviewStatus" name="interviewStatus" placeholder="All" onChange={(e) => {
                                    resetPagination();
                                    setInterviewStatus(e.target.value)
                                }}>
                                    <option value="">All</option>
                                    <option value={INTERVIEW_FINAL_STATUS_SELECTED}>{getInterviewFinalStatus(INTERVIEW_FINAL_STATUS_SELECTED)}</option>
                                    <option value={INTERVIEW_FINAL_STATUS_REJECTED}>{getInterviewFinalStatus(INTERVIEW_FINAL_STATUS_REJECTED)}</option>
                                    <option value={PROXY}>Proxy</option>
                                    <option value={QC_STATUS_FAIL}>Qc Fail</option>
                                </Form.Select>
                            </div>
                            <div className="col-6 col-lg-4 my-1 my-md-1 my-lg-auto">
                                <DatePicker className="form-select w-100" maxDate={toDate ? new Date(toDate) : null} onChange={(date) => {
                                    resetPagination();
                                    setFromDate(date);
                                    handleClear('toDate');
                                }} selected={fromDate} placeholderText="dd/mm/yyyy" dateFormat="dd/MM/yyyy" customInput={<CustomInput onClear={() => handleClear('fromDate')} />} />

                            </div>
                            <div className="col-6 col-lg-4 my-1 my-md-auto">
                                <DatePicker className="form-select w-100" minDate={fromDate ? new Date(fromDate) : null} maxDate={fromDate ? new Date(moment(fromDate).add(30, 'days')) : null} onChange={(date) => { resetPagination(); setToDate(date); }} selected={toDate} placeholderText="dd/mm/yyyy" dateFormat="dd/MM/yyyy" customInput={<CustomInput onClear={() => handleClear('toDate')} />} />
                            </div>
                            <div className="col-12 col-lg-2 my-1 my-md-auto">
                                <Button className="history-search-button w-100" onClick={() => getHistory()} >Search</Button>
                            </div>
                        </div>
                        <small className="last-one-month text-danger my-1">Available for last one month</small>
                    </div>

                </div>
                <div className="row history-feednback-row">
                    <div className="col-12 employer-dashboard-title">
                        <Card>
                            <Card.Body>
                                <Table bordered striped hover size="lg" responsive className="history-feednback-completed">
                                    <thead className='sticky-top'>
                                        <tr>
                                            <th onClick={() => onSort('_id')}>Candidate ID {getSortIcon('_id')}</th>
                                            <th onClick={() => onSort('candidate_name')}>Candidate Name {getSortIcon('candidate_name')}</th>
                                            <th onClick={() => onSort('job_data.job_title')}>Job Title {getSortIcon('job_data.job_title')}</th>
                                            <th onClick={() => onSort('companyInfo.company_name')}>Company Name {getSortIcon('companyInfo.company_name')}</th>
                                            <th onClick={() => onSort('interview.coordinatorInfo.first_name')}>Coordinator Name {getSortIcon('interview.coordinatorInfo.first_name')}</th>
                                            <th onClick={() => onSort('interview.coordinatorInfo.mobile_number')}>Coordinator Number {getSortIcon('interview.coordinatorInfo.mobile_number')}</th>
                                            <th onClick={() => onSort('scheduled_on')}>Scheduled on {getSortIcon('scheduled_on')}</th>
                                            <th onClick={() => onSort('final_status')}>Interview Status {getSortIcon('final_status')}</th>
                                            <th onClick={() => onSort('incomplete_interview')}>Incomplete Interview {getSortIcon('incomplete_interview')}</th>
                                            <th onClick={() => onSort('qc_status')}>QC Status {getSortIcon('qc_status')}</th>
                                            <th className='video-cell'>Recording</th>
                                            <th>Feedback Sheet</th>
                                            <th>Edit</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            !_.isUndefined(interviews) && !_.isNull(interviews) &&
                                            _.map(interviews,
                                                (interview, index) => {
                                                    return <tr key={"feednback-completed-" + index}>
                                                        <td className="text-start">{interview._id}</td>
                                                        <td className="text-start fw-semibold">{interview.candidate_name}</td>
                                                        <td className="text-start">{interview.job_data.job_title}</td>
                                                        <td className="text-start">{interview.companyInfo ? interview.companyInfo.company_name : ""}</td>
                                                        <td className="text-start">{interview.coordinatorInfo ? (interview.coordinatorInfo.first_name + " " + interview.coordinatorInfo.last_name) : ""}</td>
                                                        <td className="text-start">{interview.coordinatorInfo ? (interview.coordinatorInfo.mobile_number) : ""}</td>
                                                        <td className="text-start">{commonDateFormat(interview.scheduled_on)}</td>
                                                        <td className="text-start"><span className={interview.final_status}>{getInterviewFinalStatus(interview.final_status)}</span></td>
                                                        <td className="text-start">
                                                            {
                                                                interview.incomplete_interview && pascalCase(interview.incomplete_interview.replaceAll("_", " "))
                                                            }
                                                        </td>
                                                        <td className="text-start">
                                                            <span style={{
                                                                color: interview.qc_status === QC_STATUS_FAIL ? 'red' : (
                                                                    interview.qc_status === QC_STATUS_PASS ? 'green' : ''
                                                                )
                                                            }} className={interview.qc_status}>
                                                                {getQCStatuslabel(interview.qc_status)}
                                                            </span>
                                                        </td>
                                                        <td className="text-center">
                                                            {
                                                                interview.video.length > 0 && (
                                                                    <BiSolidVideos data-title="View recordings" size={20} onClick={() => {
                                                                        setRecordings(interview.video);
                                                                        setIsShowVideoModal(true);
                                                                    }} />
                                                                )
                                                            }
                                                        </td>
                                                        <td className="text-center">
                                                            {
                                                                interview.is_feedback_given && (
                                                                    <FaFileAlt className='cursor-pointer' onClick={() => {
                                                                        if (interview.feedback_sheet != "") {
                                                                            window.open(interview.feedback_sheet, '_blank');
                                                                        } else {
                                                                            window.open(`/finalInterviewReport/${interview._id}`, '_blank')
                                                                        }
                                                                    }} />
                                                                )
                                                            }
                                                        </td>
                                                        <td className="text-center">
                                                            {
                                                                !isNotEditable(interview) && (
                                                                    <NavLink to={"/live-interviewer/" + interview._id}><div className='btn btn-outline-primary btn-sm'>Edit</div></NavLink>
                                                                )
                                                            }
                                                        </td>
                                                    </tr>
                                                })
                                        }
                                    </tbody>
                                </Table>
                                {/* {
                                    interviewList.filter((item) => item.scheduler_status === SCHEDULER_STATUS_DONE) && interviewList.filter((item) => item.scheduler_status === SCHEDULER_STATUS_DONE).length > 0 && (
                                        <CustomPaginationComponent ref={paingationRef} total={interviewList.filter((item) => item.scheduler_status === SCHEDULER_STATUS_DONE).length} updatePagination={updatePagination} />
                                    )
                                } */}
                                {
                                    !_.isUndefined(interviews) && !_.isNull(interviews) && interviews.length === 0 && (
                                        <NoRecordFound />
                                    )
                                }
                                {
                                    totalCount > 0 && (
                                        <CustomPaginationComponent ref={paingationRef} total={totalCount} updatePagination={updatePagination} />
                                    )
                                }

                            </Card.Body>
                        </Card>
                    </div>
                </div>
            </div>
            {
                isShowVideoModal &&
                <Modal show={isShowVideoModal} centered>
                    <Modal.Header>
                        <Modal.Title>Recordings</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {
                            _.map(recordings, (recording, index) => {
                                return <ListGroup>
                                    <ListGroup.Item>Recording: <a rel="noreferrer" target='_blank' href={recording.url}>{recording.fileName}</a> </ListGroup.Item>
                                    {
                                        recording.transcriptUrl && (
                                            <ListGroup.Item>Transcript: <a rel="noreferrer" target='_blank' href={recording.transcriptUrl}>{recording.fileName}</a> </ListGroup.Item>
                                        )
                                    }
                                </ListGroup>
                            })
                        }
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="outline-primary" onClick={() => setIsShowVideoModal(false)}>
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>
            }
        </Fragment >
    )
}
export default HistoryScreen;