import React, { useState, useEffect, Fragment } from "react";
import _ from 'lodash';
import Loader from "react-js-loader";
import "./RequestInterviewerUsersScreen.css";
import HeaderScreen from "../HeaderScreen/HeaderScreen";
import { BsFillChatFill } from "react-icons/bs";
import { Card, Table, Form, Button, Modal, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useParams } from "react-router-dom";
import { Document, pdfjs, Page } from 'react-pdf';
import { API_URL, getAuthUserFromSessionStorage } from "../../common/constant";
import { FaSort, FaSortDown, FaSortUp } from "react-icons/fa";
import { Dialog } from "primereact/dialog";
const RequestInterviewerUsersScreen = () => {
    const [userDetails, setUserType] = useState(getAuthUserFromSessionStorage());
    const [isLoading, setIsLoading] = useState(false);
    const [showRemark, setShowRemark] = useState(false);
    const [showOpenResume, setShowOpenResume] = useState(false);
    const [formErrors, setFormErrors] = useState("");
    const [formSuccess, setFormSuccess] = useState("");
    const [showFormErrors, setShowFormErrors] = useState(true);
    const [showFormSuccess, setShowFormSuccess] = useState(true);
    const [interviewerList, setInterviewerList] = useState([]);
    const [jobDetails, setJobDetails] = useState({});
    const [interviewerDetails, setInterviewerDetails] = useState({});
    const [interviewerRemark, setInterviewerRemark] = useState("");
    const [numPages, setNumPages] = useState(0);
    const [pageNumber, setPageNumber] = useState(1);
    const { id } = useParams();
    const onDocumentLoadSuccess = ({ numPages }) => {
        setNumPages(numPages);
    }

    useEffect(() => {
        if (!_.isUndefined(interviewerList) && !_.isNull(interviewerList) && (interviewerList.length === 0)) {
            getinterviewerList();
        }
        if (!_.isUndefined(jobDetails) && !_.isNull(jobDetails) && _.isEmpty(jobDetails) && !_.isUndefined(id) && !_.isNull(id) && (id !== '')) {
            getJobDetails();
        }
    }, [interviewerList, jobDetails]);

    const getinterviewerList = () => {
        setIsLoading(true)
        fetch(`${API_URL}api/interviewerUsers`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${userDetails.token}`,
            }
        })
            .then((response) => response.json())
            .then((response) => {
                if (!_.isUndefined(response) && !_.isNull(response) && !_.isUndefined(response.data) && !_.isNull(response.data) && (response.data.length > 0)) {
                    setInterviewerList(response.data);
                }
                setIsLoading(false)
            })
            .catch((err) => {
                setIsLoading(false)
                setInterviewerList([]);
            });
    }

    const getJobDetails = () => {
        fetch(`${API_URL}api/interviewerBYID/${id}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${userDetails.token}`,
            }
        })
            .then((response) => response.json())
            .then((response) => {
                if (!_.isUndefined(response) && !_.isNull(response) && !_.isUndefined(response.data) && !_.isNull(response.data) && !_.isEmpty(response.data)) {
                    setJobDetails(response.data);
                }
            })
            .catch((err) => {
                setJobDetails([]);
            });
    }

    const changeHandler = (e) => {
        const { value } = e.target;
        setInterviewerRemark(value);
    };

    const submitInterviewerRemark = () => {
        if (_.isUndefined(interviewerRemark) || _.isNull(interviewerRemark) || (interviewerRemark.trim() === '')) {
            setShowFormErrors(true);
            setFormErrors("Remark is required!");
            return false;
        }
        let params = {
            remark: interviewerRemark,
        }
        fetch(`${API_URL}api/submitInterviewerRemark/${interviewerDetails.id}`, {
            method: 'PUT',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${userDetails.token}`,
            },
            body: JSON.stringify(params)
        })
            .then((response) => response.json())
            .then((response) => {
                if (!_.isUndefined(response) && !_.isNull(response) && !_.isUndefined(response.status) && !_.isNull(response.status) && (response.status === 200) && !_.isUndefined(response.message) && !_.isNull(response.message) && (response.message !== '')) {
                    setShowRemark(false);
                    setInterviewerRemark('');
                    setInterviewerDetails({});
                    setShowFormSuccess(true);
                    setFormSuccess(response.message);
                }
                else if (!_.isUndefined(response) && !_.isNull(response) && !_.isUndefined(response.message) && !_.isNull(response.message) && (response.message !== '')) {
                    setShowFormErrors(true);
                    setFormErrors(response.message);
                }
            })
            .catch((error) => {
                if (!_.isUndefined(error) && !_.isNull(error) && !_.isUndefined(error.message) && !_.isNull(error.message) && (error.message !== '')) {
                    setShowFormErrors(true);
                    setFormErrors(error.message);
                }
            });
    }
    const handleCloseFormErrors = () => {
        setShowFormErrors(false);
        setFormErrors("");
    };
    const handleCloseFormSuccess = () => {
        setShowFormSuccess(false);
        setFormSuccess("");
    };

    const [sortConfig, setSortConfig] = useState({ key: 'createdAt', direction: 'desc' });

    const onSort = (key) => {
        let direction = 'asc';
        if (sortConfig.key === key && sortConfig.direction === 'asc') {
            direction = 'desc';
        }
        setSortConfig({ key, direction });
    };

    useEffect(() => {
        sortData(interviewerList, sortConfig.key, sortConfig.direction);
    }, [sortConfig])

    const sortData = (list, key, direction) => { 
        const sortedData = list.sort((a, b) => {
            var value1 = a[key];
            var value2 = b[key];

            if (key === 'name') {
                value1 = a['first_name'] + " " + a['last_name'];
                value2 = b['first_name'] + " " + b['last_name'];
            }

            if (typeof value1 === 'string' && typeof value2 === 'string') {
                if (direction === 'asc') {
                    return value1.localeCompare(value2);
                } else {
                    return value2.localeCompare(value1);
                }
            }

            if (value1 < value2) return direction === 'asc' ? -1 : 1;
            if (value1 > value2) return direction === 'asc' ? 1 : -1;

            return 0;
        });

        setInterviewerList(sortedData)
    }

    const getSortIcon = (key) => {
        if (sortConfig.key !== key) {
            return <FaSort className="cursor-pointer"/>;
        }
        if (sortConfig.direction === 'desc') {
            return <FaSortUp className="cursor-pointer"/>;
        }
        return <FaSortDown className="cursor-pointer"/>;
    };

    return (
        <Fragment>
            {
                isLoading &&
                <div className="spinner-loader">
                    <Loader type="spinner-circle" bgColor="#2F2F74" color="#2F2F74" size={100} />
                </div>
            }
            <div className="container-fluid request-interviewer-users-container">
                <div className="row">
                    <div className="col-12 px-0">
                        <HeaderScreen />
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 my-2">
                        <Card>
                            <Card.Body><b className="request-interviewer-users-jobdetails">{jobDetails._id}/{jobDetails.job_title}/Requested Interviewers</b></Card.Body>
                        </Card>
                    </div>
                </div>
                <div className="row request-interviewer-users-feednback-row">
                    <div className="col-12">
                        <Card>
                            <Card.Body>
                                <Table borderless responsive className="request-interviewer-users-feednback-completed">
                                    <thead>
                                        <tr>
                                            <th onClick={() => onSort('name')}>Interviewer Name {getSortIcon('name')}</th>
                                            <th onClick={() => onSort('resume')}>Resume {getSortIcon('resume')}</th>
                                            <th onClick={() => onSort('status')}>Approve Or Reject {getSortIcon('status')}</th>
                                            <th>Remark</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            !_.isUndefined(interviewerList) && !_.isNull(interviewerList) &&
                                            _.map(interviewerList, (interviewer, index) => {
                                                return <tr key={"feednback-completed-" + index}>
                                                    <td>{interviewer.first_name + " " + interviewer.last_name}</td>
                                                    <td>
                                                        <OverlayTrigger
                                                            placement="bottom"
                                                            overlay={<Tooltip>Candidate Resume</Tooltip>}
                                                        >
                                                        <a onClick={() => {
                                                                setInterviewerDetails(interviewer); 
                                                                setShowOpenResume(true);
                                                            }} href='#'>
                                                                {(new URL(interviewer.resume).pathname.split('/').pop().replaceAll("%20", " "))}
                                                            </a> 
                                                        </OverlayTrigger>
                                                    </td>
                                                    <td>{interviewer.status}</td>
                                                    <td><BsFillChatFill size={24} onClick={() => { setInterviewerDetails(interviewer); setShowRemark(true); }} /></td>
                                                </tr>
                                            })
                                        }
                                    </tbody>
                                </Table>
                            </Card.Body>
                        </Card>
                    </div>
                </div>
            </div>
            {
                showRemark && !_.isUndefined(interviewerDetails) && !_.isNull(interviewerDetails) && !_.isUndefined(interviewerDetails.id) && !_.isNull(interviewerDetails.id) && (interviewerDetails.id !== '') &&
                <Modal centered show={showRemark} onHide={() => { setShowRemark(false) }} animation={false}>
                    <Modal.Body>
                        <div className="row mt-4">
                            <div className="col-12 mb-2"><b>Remark: </b></div>
                            <div className="col-12 mb-2">
                                <Form.Control as="textarea" rows={3} onChange={changeHandler} placeholder="Remark" />
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => { setShowRemark(false) }}>
                            Cancel
                        </Button>
                        <Button variant="primary" onClick={submitInterviewerRemark}>
                            Submit
                        </Button>
                    </Modal.Footer>
                </Modal>
            }

            <Dialog header="Candidate Resume" visible={showOpenResume} style={{ width: '50vw', height: '80vh'  }} onHide={() => setShowOpenResume(false)} dismissableMask="true">
                {
                    interviewerDetails && interviewerDetails.resume &&
                    (
                        interviewerDetails.resume.includes('.doc') ? 
                        <iframe key="iframe-doc" style={{
                            width: '100%',
                            height: '100%'
                        }} src={`https://docs.google.com/gview?url=${interviewerDetails.resume}&embedded=true`}></iframe> : 
                        <object key="object-other" data={interviewerDetails.resume} style={{
                            width: '100%',
                            height: '100%'
                        }}></object>
                    )
                }
            </Dialog>
            {
                formErrors && showFormErrors &&
                <Modal show={showFormErrors} onHide={handleCloseFormErrors} animation={false}>
                    <Modal.Header closeButton>
                        <Modal.Title>Error</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>{formErrors}</Modal.Body>
                    <Modal.Footer>
                        <Button variant="primary" onClick={handleCloseFormErrors}>
                            OK
                        </Button>
                    </Modal.Footer>
                </Modal>
            }
            {
                formSuccess && showFormSuccess &&
                <Modal show={showFormSuccess} onHide={handleCloseFormSuccess} animation={false}>
                    <Modal.Header closeButton>
                        <Modal.Title>Success</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>{formSuccess}</Modal.Body>
                    <Modal.Footer>
                        <Button variant="primary" onClick={handleCloseFormSuccess}>
                            OK
                        </Button>
                    </Modal.Footer>
                </Modal>
            }
        </Fragment>
    )
}
export default RequestInterviewerUsersScreen;