import React, { forwardRef, useImperativeHandle, useRef, useState } from 'react';
import Pagination from 'react-bootstrap/Pagination';
import { FaAngleDoubleLeft, FaAngleDoubleRight } from 'react-icons/fa';
import { ITEMS_PER_PAGE } from '../constant';
import './CustomPaginationComponent.css';

const CustomPaginationComponent = forwardRef(({ total, updatePagination }, ref) => {
  useImperativeHandle(ref, () => ({
    setCurrentPage
  }));

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(ITEMS_PER_PAGE);

  const handlePageChange = (page) => {
    setCurrentPage(page);
    updatePagination(page, itemsPerPage)
  };

  const renderPageNumbers = () => {
    const pageNumbers = [];
    const totalPages = Math.ceil(total / itemsPerPage);
    // Determine the starting page number to display
    let startPage = Math.max(1, currentPage - 1);
    // Show a maximum of 2 page numbers
    for (let i = startPage; i <= Math.min(totalPages, startPage + 1); i++) {
      pageNumbers.push(
        <Pagination.Item
          key={i}
          active={i === parseInt(currentPage)}
          onClick={() => handlePageChange(i)}
        >
          {i}
        </Pagination.Item>
      );
    }
    // Add an ellipsis if there are more pages after the displayed page numbers
    if (startPage + 1 < totalPages) {
      pageNumbers.push(<Pagination.Ellipsis disabled key="ellipsis" />);
    }
    return pageNumbers;
  };

  const handleItemsPerPageChange = (e) => {
    setItemsPerPage(parseInt(e.target.value));
    setCurrentPage(1);
    updatePagination(1, parseInt(e.target.value));
  };

  return (
    <>
      <div className="d-flex justify-content-between align-items-center mb-4 w-100">
        {
          total > ITEMS_PER_PAGE && (
            <select style={{ width: '80px' }} className="paginate-option form-select" onChange={handleItemsPerPageChange}>
              <option value={20}>20</option>
              <option value={40}>40</option>
              <option value={60}>60</option>
              <option value={80}>80</option>
            </select>
          )
        }
        {
          total > ITEMS_PER_PAGE && (
            <Pagination>
              <Pagination.Prev
                onClick={() => handlePageChange(1)}
                disabled={currentPage === 1}
              >
                <FaAngleDoubleLeft />
              </Pagination.Prev>
              <Pagination.Prev
                onClick={() => handlePageChange(currentPage - 1)}
                disabled={currentPage === 1}
              />
              {renderPageNumbers()}
              <Pagination.Next
                onClick={() => handlePageChange(currentPage + 1)}
                disabled={currentPage === Math.ceil(total / itemsPerPage)}
              />
              <Pagination.Prev
                onClick={() => handlePageChange(Math.ceil(total / itemsPerPage))}
                disabled={currentPage === Math.ceil(total / itemsPerPage)}
              >
                <FaAngleDoubleRight />
              </Pagination.Prev>
            </Pagination>
          )
        }
      </div>
    </>
  );
});

export default CustomPaginationComponent;
