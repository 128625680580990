import React, { useState, Fragment, useRef, useEffect } from "react";
import _ from 'lodash';
import Loader from "react-js-loader";
import { useNavigate } from "react-router-dom";
import faster_hiring from "../../Assets/faster_hiring.png";
import candidate from "../../Assets/candidate.svg";
import completed from "../../Assets/completed.svg";
import interviewers from "../../Assets/interviewers.svg";
import registered from "../../Assets/registered.svg";
import qoute from "../../Assets/quote.svg";
import rala_portal_logo from "../../Assets/rala_infotech_color.svg";
import { Form, Button, Alert, Modal } from 'react-bootstrap';
import { Toast } from "primereact/toast";
import "./ForgotPasswordScreen.css";
import { API_URL } from "../../common/constant";

const ForgotPasswordScreen = () => {
  const navigate = useNavigate();
  const [isSubmit, setIsSubmit] = useState(false);
  const [emailError, setIsEmailError] = useState("");
  const [wrongCode, setWrongCode] = useState(false);
  const [wrongCodeError, setWrongCodeError] = useState("");

  const toast = useRef(null);
  const [user, setUserDetails] = useState({
    id: "",
    email: "",
    verification_code: ""
  });
  const [verificationCode, setVerificationCode] = useState("");
  const [codeSendDate, setCodeSendDate] = useState(new Date());
  const [activeIndex, setActiveIndex] = useState(0);
  const [websiteStatistics, setWebsiteStatistics] = useState({
    registeredCandidates: 0,
    completedInterviews: 0,
    registeredCompanies: 0,
    interviewers: 0,
    activeTestimonial: null,
    testimonials: []
  });

  const changeHandler = (e) => {
    const { name, value } = e.target;
    setUserDetails({
      ...user,
      [name]: value,
    });
  };
  const validateForm = () => {
    const emailregex = /^[^\s+@]+@[^\s@]+\.[^\s@]{2,}$/i;
    if (_.isUndefined(user.email) || _.isNull(user.email) || (user.email === '') || (user.email.trim() === '')) {
      setIsEmailError("Email Address is required!");
    }
    else if (!_.isUndefined(user.email) && !_.isNull(user.email) && (user.email !== '') && (user.email.trim() !== '') && !emailregex.test(user.email)) {
      setIsEmailError("Please enter a valid email address!");
    }
    else if (!_.isUndefined(user.email) && !_.isNull(user.email) && (user.email !== '') && (user.email.trim() !== '') && ((user.email.trim().length < 10) || (user.email.trim().length > 150))) {
      setIsEmailError("Email address must be between 10 to 150 characters!");
    }
    else {
      setIsEmailError("");
    }
  };
  const checkedValidateForm = () => {
    const emailregex = /^[^\s+@]+@[^\s@]+\.[^\s@]{2,}$/i;
    if (_.isUndefined(user.email) || _.isNull(user.email) || (user.email === '') || (user.email.trim() === '')) {
      return false;
    }
    else if (!_.isUndefined(user.email) && !_.isNull(user.email) && (user.email !== '') && (user.email.trim() !== '') && !emailregex.test(user.email)) {
      return false;
    }
    else if (!_.isUndefined(user.email) && !_.isNull(user.email) && (user.email !== '') && (user.email.trim() !== '') && ((user.email.trim().length < 15) || (user.email.trim().length > 150))) {
      return false;
    }
    return true;
  };
  const submitHandler = (e) => {
    e.preventDefault();
    validateForm();
    if (checkedValidateForm()) {
      setIsSubmit(true);
      let params = {
        email: user.email,
      }
      fetch(`${API_URL}api/forgetPassword`, {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(params)
      })
        .then((response) => response.json())
        .then((response) => {
          setIsSubmit(false);
          if (!_.isUndefined(response) && !_.isNull(response) && !_.isUndefined(response.status) && !_.isNull(response.status) && (response.status === 200) && !_.isUndefined(response.message) && !_.isNull(response.message) && (response.message !== '')) {
            setVerificationCode(response.data.verificationCode);
            setCodeSendDate(new Date())
            setUserDetails({
              ...user,
              id: response.data.id
            });
          }
          else if (!_.isUndefined(response) && !_.isNull(response) && !_.isUndefined(response.message) && !_.isNull(response.message) && (response.message !== '')) {
            toast.current.show({ severity: 'error', summary: 'Error', detail: response.message, life: 3000 });
          }
        })
        .catch((error) => {
          if (!_.isUndefined(error) && !_.isNull(error) && !_.isUndefined(error.message) && !_.isNull(error.message) && (error.message !== '')) {
            toast.current.show({ severity: 'error', summary: 'Error', detail: error.message, life: 3000 });
          }
          setIsSubmit(false);
        });
    }
  };

  const getWebsiteStatistics = () => {
    fetch(`${API_URL}api/getWebsiteStatistics`)
      .then((response) => response.json())
      .then((response) => {
        if (response.data) {
          setWebsiteStatistics({
            registeredCandidates: response.data.registeredCandidates,
            completedInterviews: response.data.completedInterviews,
            registeredCompanies: response.data.registeredCompanies,
            interviewers: response.data.interviewers,
            activeTestimonial: response.data.testimonials.length > 0 ? response.data.testimonials[0] : [],
            testimonials: response.data.testimonials
          })
        }
      })
      .catch((error) => {
        setWebsiteStatistics([])
      });
  }

  const nextTestimonial = (index) => {
    const nextIndex = index < websiteStatistics.testimonials.length ? index : 0;
    setActiveIndex(nextIndex)
    setWebsiteStatistics((prevStats) => ({
      ...websiteStatistics,
      activeTestimonial: websiteStatistics.testimonials[nextIndex],
    }));
  };

  useEffect(() => {
    if (websiteStatistics.activeTestimonial === null) {
      getWebsiteStatistics();
    }
  }, []);

  useEffect(() => {
    if (websiteStatistics) {
      const timer = setTimeout(() => {
        const currentIndex = activeIndex + 1;
        setActiveIndex(currentIndex)
        nextTestimonial(currentIndex)
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [websiteStatistics]);

  return (
    <Fragment>
      <Toast ref={toast} />
      {
        isSubmit &&
        <div className="spinner-loader">
          <Loader type="spinner-circle" bgColor="#2F2F74" color="#2F2F74" size={100} />
        </div>
      }
      <div className="d-flex align-items-md-center h-100">
        <div className="container-fluid h-100">
          <div className="row d-flex h-100">
            <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6 text-center d-none d-lg-block dynamic_hiring">
              <div className="d-flex justify-content-center flex-column h-100 px-4">
                <div className="row">
                  <div className="col-12 col-sm-12 col-md-6 col-lg-6 text-start pt-5 tilt">
                    <div className="icon-wrap">
                      <img src={candidate} alt="candidate-icon" />
                    </div>
                    <div className="fw-bold display-5 text-white text-uppercase mt-4">
                      {websiteStatistics.registeredCandidates}+
                    </div>
                    <div className="fw-bold text-uppercase text_highlite mt-3">
                      CANDIDATES REGISTERED
                    </div>
                  </div>
                  <div className="col-12 col-sm-12 col-md-6 col-lg-6 text-start pt-5 tilt">
                    <div className="icon-wrap">
                      <img src={completed} alt="completed-icon" />
                    </div>
                    <div className="fw-bold display-5 text-white text-uppercase mt-4">
                      {websiteStatistics.completedInterviews}+
                    </div>
                    <div className="fw-bold text-uppercase text_highlite mt-3">
                      COMPLETED INTERVIEWS
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 col-sm-12 col-md-6 col-lg-6 text-start pt-5 tilt">
                    <div className="icon-wrap">
                      <img src={registered} alt="registered-icon" />
                    </div>
                    <div className="fw-bold display-5 text-white text-uppercase mt-4">
                      {websiteStatistics.registeredCompanies}+
                    </div>
                    <div className="fw-bold text-uppercase text_highlite mt-3">
                      COMPANIES REGISTERED
                    </div>
                  </div>
                  <div className="col-12 col-sm-12 col-md-6 col-lg-6 text-start pt-5 tilt">
                    <div className="icon-wrap">
                      <img src={interviewers} alt="interviewers-icon" />
                    </div>
                    <div className="fw-bold display-5 text-white text-uppercase mt-4">
                      {websiteStatistics.interviewers}+
                    </div>
                    <div className="fw-bold text-uppercase text_highlite mt-3">
                      INTERVIEWERS
                    </div>
                  </div>
                </div>
                <div className="row testimonials pt-2">
                  {
                    websiteStatistics && websiteStatistics.activeTestimonial && (
                      <>
                        <div className="col-12 col-sm-12 col-md-12 col-lg-16 text-start pt-5">
                          <img src={qoute} className="mb-4" alt="Quote" />
                          <p className="text_highlite active_testimonial_text">{websiteStatistics.activeTestimonial.message}</p>
                          <div className="text-white text-uppercase fw-bold">{websiteStatistics.activeTestimonial.author}</div>
                          <div className="text_highlite_t">{websiteStatistics.activeTestimonial.designation}</div>
                        </div>
                        <div className="carousel-bullets mt-3">
                          {
                            websiteStatistics.testimonials.map((item, index) => {
                              return <div onClick={() => nextTestimonial(index)} className={item._id === websiteStatistics.activeTestimonial._id ? 'bullet active' : 'bullet'}></div>
                            })
                          }
                        </div>
                      </>
                    )
                  }
                </div>
              </div>
            </div>
            <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6 m-auto">
              <div className="row justify-content-md-center">
                <div className="col-sm-12 col-md-8 col-lg-9 col-xl-7 col-xxl-6">
                  <div className="d-flex justify-content-center logo mb-5"><img src={rala_portal_logo} alt="Rala Portal" /></div>
                  <Form className="mt-4" autoComplete="off">
                    <Form.Group className="mb-3">
                      <Form.Control type="email" id="email" name="email" placeholder="Email Address" onChange={changeHandler} maxLength={150} autoComplete="off" aria-describedby="enterVerificationCode" />
                      {
                        !_.isUndefined(emailError) && !_.isNull(emailError) && (emailError !== '') &&
                        <Alert variant="danger" className="mt-2">{emailError}</Alert>
                      }
                      <br />
                      <Form.Text id="enterVerificationCode" className="enterVerificationCode">(Verification code will be sent to the registered mail ID)</Form.Text>
                      {
                        verificationCode != "" && (
                          <>
                            <div style={{
                              textAlign: 'right'
                            }}>
                              <a onClick={(e) => {
                                submitHandler(e);
                                setWrongCode(false);
                                setWrongCodeError("");
                              }} href="javascipt:void(0);" className="forget-password">resend</a>
                            </div>
                            {
                              !_.isUndefined(wrongCode) && !_.isNull(wrongCode) && (wrongCode == true) &&
                              <Alert variant="danger" className="mt-2">{wrongCodeError}</Alert>
                            }
                            <Form.Control className="mt-3" type="text" id="verificationCode" name="verification_code" placeholder="Enter verification code" onChange={changeHandler} maxLength={150} autoComplete="off" aria-describedby="enterVerificationCode" />
                          </>
                        )
                      }
                    </Form.Group>
                    <Form.Group className="mb-3">
                      {
                        verificationCode !== "" ? (
                          <Button className="sign-up-form-submit-button" onClick={() => {
                            if (user && user.verification_code && user.verification_code == verificationCode) {
                              const timeDifference = (new Date()).getTime() - codeSendDate.getTime();
                              const secondsDifference = timeDifference / 1000;
                              if (secondsDifference > 1800) {
                                setWrongCode(true)
                                setWrongCodeError("code is expired");
                              } else {
                                setWrongCode(false);
                                setWrongCodeError("");
                                navigate(`/new-password/${user.id}`)
                              }
                            } else {
                              setWrongCode(true)
                              setWrongCodeError("entered code is wrong");
                            }
                          }}>Verify</Button>
                        ) : (
                          <Button type="submit" className="sign-up-form-submit-button" onClick={submitHandler}>Submit</Button>
                        )
                      }
                    </Form.Group>
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};
export default ForgotPasswordScreen;